import React, { useContext, useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Link } from "react-router-dom";
import { ThemeContext } from '../../ThemeContaxt/ThemeContaxt';
import { GET_APP_DATA } from '../../components/Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';

const DownloadApp = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [appNames, setAppNames] = useState([])

    useEffect(() => {
        getAppData();
    }, []);

    const getAppData = async () => {
        try {
            const {
                data: { status, data },
            } = await axios.get(GET_APP_DATA);
            if (status === 200) {
                setAppNames(data);
            } else {
                // Handle other statuses if needed
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong, please try again!');
        }
    };

    // console.log(appNames)

    return (
        <>
            <Header />

            <section className="free-testing-sec">
                <div className="container">

                    <div className={`pagination ${isDarkMode ? 'text_color_light' : 'text_color_dark'}`}>
                        <span>
                            <Link to="/user/dashboard">
                                <i className="fas fa-chevron-left" />
                                Back
                            </Link>
                        </span>
                    </div>

                    <h2>Download the app for iOS or Android</h2>
                    <div className="row mt-5">
                        <div className="col-md-6 col-12 mb-4">
                            
                                <a href="#" target='_blank' download="Examtice.ipa" className="app common-btn d-flex justify-content-center">
                                    {/* Your SVG or image */}
                                    <svg xmlns="http://www.w3.org/2000/svg" height="35" width="45" viewBox="0 0 384 512"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" fill="#fff" /></svg>
                                    <h2>Download iSO</h2>
                                </a>

                        </div>
                        <div className="col-md-6 col-12">
                            <a  href="assets/apps/examtice.apk" target='_blank' download="Examtice.apk" className="resources common-btn d-flex justify-content-center align-item-center">
                                {/* <img style={{ width: "30px", marginRight: "10px" }} src={android} alt="" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" height="35" width="45" viewBox="0 0 640 512" style={{ marginRight: "10px", color: "white" }}><path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z" fill="#fff" /></svg>
                                <h2>Download Android</h2>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default DownloadApp