import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { MY_TICKETLIST, DELETE_TICKET, DELETE_ALL_TICKET } from "../../components/Api";
import Cookies from "js-cookie";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Spinner from "../../components/spinner/Spinner";
import { getTestDate } from "../../components/CommonFunction";
import { ThemeContext } from "../../ThemeContaxt/ThemeContaxt";
import Pagination from "../../Pagination/Pagination";
import Modal from "react-modal";

const Supportlist = () => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [tickets, setTicketdata] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5); // Set the number of tickets per page
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deleteMultipleItemModal, setDeleteMultipleItemModal] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(false);
    const [deleteItemModal, setDeleteItemModal] = useState(false);

    const config = {
        headers: {
            Authorization: Cookies.get('token'),
        },
    };

    useEffect(() => {
        getTicketlist();
    }, [currentPage]); // Add currentPage as a dependency to trigger the effect when the page changes

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: "9999",
            overflowY: "auto",
        }
    }

    const getTicketlist = async () => {
        try {
            setLoader(true);
            var user_data = JSON.parse(Cookies.get('user_data'));
            const body = {
                userId: user_data.id,
            };
            const {
                data: { message, status, data },
            } = await axios.post(MY_TICKETLIST, body, config);
            console.log(data, 'data');
            if (status === 200) {
                setLoader(false);
                setTicketdata(data);
            } else {
                setLoader(false);
                toast.error(message);
            }
        } catch (error) {
            setLoader(false);
            if (error.response && error.response.data && error.response.data.status === 422) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Something went wrong, please try again!');
            }
        }
    };

    const { isDarkMode } = useContext(ThemeContext);

    const indexOfLastTicket = currentPage * postsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - postsPerPage;
    const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // All select btn 
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);

        // Update the globally selected items based on all items
        const allItemIds = tickets.map((item) => item.id);

        if (!selectAll) {
            setSelectedItems(allItemIds);
        } else {
            setSelectedItems([]);
        }
    };

    // single item select btn 
    const handleCheckboxChange = (itemId) => {
        // console.log(itemId)
        // Toggle the selection for the current item on the current page
        setSelectedItems((prevSelected) => {
            if (prevSelected.includes(itemId)) {
                return prevSelected.filter((id) => id !== itemId);
            } else {
                return [...prevSelected, itemId];
            }
        });
    };

    // open modal for multiple items 
    const handleDelectListMultipleModal = (id) => {
        // setDeleteItemId(id)
        if (selectedItems.length == 0) {
            return toast.error("Please select items")
        }
        setDeleteMultipleItemModal(true)
    }

    // multiple item delete 
    const handleDelectListMultiple = async (multipleItems) => {
        // e.preventDefault();
        // console.log(multipleItems)
        try {
            setLoader(true);
            var userId =
                Cookies.get("user_data") && JSON.parse(Cookies.get("user_data"));
            const body = {
                ticket_ids: selectedItems,
                user_id: userId.id
            };
            const {
                data: { message, status, data, refress },
            } = await axios.post(DELETE_ALL_TICKET, body);
            // console.log(message, "data");
            if (status == 200) {
                // setExamlist(prevExams => prevExams.filter(exam => exam.multipleItems !== multipleItems));

                getTicketlist()
                setDeleteMultipleItemModal(false)
                setLoader(false)
                toast.success(message)
            } else {
                setLoader(false);
                toast.error(message);
            }
        } catch (error) {
            setLoader(false);
            if (error.response.data.status == 422) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong, please try again.!");
            }
        }
    };

    // open modal for delete 
    const handleDelectListSingleModal = (id) => {
        setDeleteItemId(id)
        setDeleteItemModal(true)
    }

    // single item delete 
    const handleDelectListSingle = async (id) => {
        // e.preventDefault();
        // console.log(id)
        try {
            setLoader(true);
            var userId =
                Cookies.get("user_data") && JSON.parse(Cookies.get("user_data"));
            const body = {
                ticket_id: deleteItemId,
                user_id: userId.id

            };
            const {
                data: { message, status, data, refress },
            } = await axios.post(DELETE_TICKET, body);
            console.log(message, "data");
            if (status == 200) {
                // setExamlist(prevExams => prevExams.filter(exam => exam.id !== id));
                
                getTicketlist()
                setDeleteItemModal(false)
                setLoader(false)
                toast.success(message)
            } else {
                setLoader(false);
                toast.error(message);
            }
        } catch (error) {
            setLoader(false);
            if (error.response.data.status == 422) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong, please try again.!");
            }
        }
    };

    return (
        <>
            <Header />
            <section className="free-testing-sec">
                <div className="container">
                    <div className={`pagination row dash-graph`}>
                        <div className="col-md-6">
                            <span>
                                <Link to="/user/dashboard" className={`${isDarkMode ? 'color_light' : 'color_dark'}`}>
                                    <i className="fas fa-chevron-left" />
                                    Dashboard
                                </Link>
                            </span>
                        </div>
                        <div className="col-md-6">
                            <span className="right">
                                <Link to="/user/addticket" className="common-btn">
                                    Create Ticket
                                </Link>
                            </span>
                        </div>
                    </div>

                    <div className="row savedTest">
                        {loader ? (
                            <div className="spinner_div" style={{ minHeight: "400px" }}>
                                <Spinner />
                            </div>
                        ) : (
                            <>
                                {/* <div className="report-menu">
                <div className="category free-testing-tabs">
                  <ul>
                    <li>
                      <p>{report.userReport?.length}</p>
                      Tests taken
                    </li>
                    <li>
                      <p>{report.totalQues}</p>
                      Questions seen
                    </li>
                    <li>
                      <p>{report.totalTime}</p>
                      Time spent
                    </li>
                  </ul>
                </div>
              </div> */}

                                <table className={`${isDarkMode ? 'table table-hover table-dark' : 'table table-hover table-white'}`}>
                                    <thead>
                                        <tr>
                                            <th scope="col-1">
                                                <span className="d-flex">
                                                    <input
                                                        type="checkbox"
                                                        className="mr-2"
                                                        checked={selectAll}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                    All
                                                </span>
                                            </th>
                                            <th scope="col">S.no</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Date</th>
                                            <th scope="col" style={{ width: "10%" }}>
                                                View
                                            </th>
                                            <th>
                                                <button className="examlist-delect-all-btn" onClick={() => handleDelectListMultipleModal()}>Delete All</button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTickets.length ? (
                                            currentTickets.map((item, index) => (
                                                <tr key={index}>
                                                    <td scope="row">
                                                        <input
                                                            type="checkbox"
                                                            value={item.id}
                                                            id={item.id}
                                                            name="question_id"
                                                            checked={selectedItems.includes(item.id)}
                                                            onChange={() => handleCheckboxChange(item.id)}
                                                        />
                                                    </td>
                                                    <td scope="row">{(currentPage - 1) * postsPerPage + index + 1}</td>
                                                    <td scope="row"> {item.title}</td>
                                                    <td>
                                                        {item.status_id == 3 ? (
                                                            <span className="badge-success badge mr-2">
                                                                Open
                                                            </span>
                                                        ) : (
                                                            <>
                                                                {item.status_id == 4 ? (
                                                                    <span className="badge-danger badge mr-2">Close</span>
                                                                ) : (
                                                                    <span className="badge-warning badge mr-2">Awaiting</span>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                    <td>{item.category_name}</td>
                                                    <td>{getTestDate(item.created_at)}</td>
                                                    <td className={`${isDarkMode ? 'color_light' : 'color_dark'}`}>
                                                        <Link to={"/user/supportdetails/" + item.id}>
                                                            <span>
                                                                View <i className="fas fa-chevron-right"></i>
                                                            </span>
                                                        </Link>
                                                    </td>
                                                    <td scope="row"><button className="examlist-delect-btn" onClick={() => handleDelectListSingleModal(item.id)}>Delete</button></td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>No Ticket found..!</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                </div>
                <Pagination postsPerpage={postsPerPage} totalPosts={tickets.length} paginate={paginate} setCurrentPage={setCurrentPage} currentPage={currentPage}></Pagination>
            </section>


            <Modal
                isOpen={deleteItemModal}
                //onRequestClose={() => setTestWarning(false)}
                style={customStyles}
                contentLabel="Finish test modal"
                className="logout-modals"
                id="exampleModalLong"
                shouldReturnFocusAfterClose={false}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="payment-sucess">
                                <p>Delect Warning</p>
                                <img src="assets/images/warning.png" alt="" />
                                <p>Are you sure you want to Delect item?</p>
                                <div className="row">
                                    {/* {saveLoader && <Spinner />} */}
                                    <button
                                        href="#"
                                        onClick={handleDelectListSingle}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setLoader(false)
                                            setDeleteItemModal(false);
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={deleteMultipleItemModal}
                //onRequestClose={() => setTestWarning(false)}
                style={customStyles}
                contentLabel="Finish test modal"
                className="logout-modals"
                id="exampleModalLong"
                shouldReturnFocusAfterClose={false}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="payment-sucess">
                                <p>Delect Warning</p>
                                <img src="assets/images/warning.png" alt="" />
                                <p>Are you sure you want to Delect item?</p>
                                <div className="row">
                                    {/* {saveLoader && <Spinner />} */}
                                    <button
                                        href="#"
                                        onClick={handleDelectListMultiple}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setLoader(false)
                                            setDeleteMultipleItemModal(false);
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />
        </>
    );
};
export default Supportlist;
