// This is my old code

// import React from 'react'
// const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage, setCurrentPage}) => {
//     const pageNumbers = [];
//     const nPage = Math.ceil(totalPosts / postsPerpage)

//     console.log(totalPosts)
//     console.log(postsPerpage)

//     for (let i = 1; i <= nPage; i++) {
//         pageNumbers.push(i);
//     }

//     const prePage = () => {
//         if (currentPage !== 1) {
//             setCurrentPage(currentPage - 1)
//         }
//     }

//     const nextPage = () => {
//         if (currentPage !== nPage) {
//             setCurrentPage(currentPage + 1)
//         }
//     }

//     return (
//         <nav>
//             <ul className='pagination pagination-gap'>
//                 {/* <li className='page-item'>
//                     <a className='page-link' onClick={prePage}>Pre</a>
//                 </li> */}
//                 {currentPage !== 1 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={prePage}>
//                             Pre
//                         </a>
//                     </li>
//                 )}

//                 {/* old correct code  */}
//                 {/* {pageNumbers.map(number => (
//                     <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                         <a onClick={() => paginate(number)} className='page-link'>
//                             {number}
//                         </a>
//                     </li>
//                 ))} */}
//                 {/* old correct code end */}


//                 {/* new code for pagination  */}
//                 {pageNumbers.map(number => (
//                     <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                         <a onClick={() => paginate(number)} className='page-link'>
//                             {number} 
//                         </a>
//                     </li>
//                 ))}
//                 {/* new code for pagination  end*/}

//                 {/* {currentPage !== nPage && ( */}
//                 {currentPage !== nPage && nPage !== 0 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={nextPage}>
//                             Next
//                         </a>
//                     </li>
//                 )}

//             </ul>
//         </nav>
//     )
// }

// export default Pagination;




// This is work but ... not show

// import React from 'react';

// const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage }) => {
//     const pageNumbers = [];
//     const nPages = Math.ceil(totalPosts / postsPerpage);

//     console.log(nPages)

//     for (let i = 1; i <= nPages; i++) {
//         pageNumbers.push(i);
//     }

//     // Function to generate dynamic page numbers based on current page
//     const getDisplayPageNumbers = () => {
//         const maxDisplayedPages = 5; // Adjust this number as needed
//         const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);

//         if (currentPage <= halfDisplayedPages) {
//             return pageNumbers.slice(0, maxDisplayedPages);
//         } else if (currentPage >= nPages - halfDisplayedPages) {
//             return pageNumbers.slice(nPages - maxDisplayedPages, nPages);
//         } else {
//             return pageNumbers.slice(currentPage - halfDisplayedPages - 1, currentPage + halfDisplayedPages);
//         }
//     };

//     console.log(getDisplayPageNumbers())

//     return (
//         <nav>
//             <ul className='pagination pagination-gap'>
//                 {currentPage !== 1 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(currentPage - 1)}>
//                             Pre
//                         </a>
//                     </li>
//                 )}

//                 {getDisplayPageNumbers().map((number, index) => (
//                     <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                         <a onClick={() => paginate(number)} className='page-link'>
//                             {number}
//                         </a>
//                     </li>
//                 ))}

//                 {currentPage !== nPages && nPages !== 0 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(currentPage + 5)}>
//                             Next
//                         </a>
//                     </li>
//                 )}
//             </ul>
//         </nav>
//     );
// };

// export default Pagination;



// This is ... show

// import React from 'react';

// const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage }) => {
//     const pageNumbers = [];
//     const nPages = Math.ceil(totalPosts / postsPerpage);

//     for (let i = 1; i <= nPages; i++) {
//         pageNumbers.push(i);
//     }

//     // Function to generate dynamic page numbers based on current page
//     const getDisplayPageNumbers = () => {
//         const maxDisplayedPages = 5; // Adjust this number as needed

//         if (nPages <= maxDisplayedPages) {
//             return pageNumbers;
//         }

//         const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);

//         if (currentPage <= halfDisplayedPages) {
//             return [...pageNumbers.slice(0, maxDisplayedPages - 1), '...', pageNumbers[nPages - 1]];
//         } else if (currentPage >= nPages - halfDisplayedPages) {
//             return [pageNumbers[0], '...', ...pageNumbers.slice(nPages - maxDisplayedPages + 2, nPages)];
//         } else {
//             return [pageNumbers[0], '...', ...pageNumbers.slice(currentPage - halfDisplayedPages, currentPage + halfDisplayedPages), '...', pageNumbers[nPages - 1]];
//         }
//     };

//     return (
//         <nav>
//             <ul className='pagination pagination-gap'>
//                 {currentPage !== 1 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(currentPage - 1)}>
//                             Pre
//                         </a>
//                     </li>
//                 )}

//                 {getDisplayPageNumbers().map((number, index) => {
//                     return (
//                         <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                             {number === '...' ? (
//                                 <span className='page-link'>...</span>
//                             ) : (
//                                 <a onClick={() => paginate(number)} className='page-link'>
//                                     {number}
//                                 </a>
//                             )}
//                         </li>
//                     );
//                 })}

//                 {currentPage < nPages && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(currentPage + 1)}>
//                             Next
//                         </a>
//                     </li>
//                 )}
//             </ul>
//         </nav>
//     );
// };

// export default Pagination;








// import React from 'react';

// const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage }) => {
//     const pageNumbers = [];
//     const nPages = Math.ceil(totalPosts / postsPerpage);

//     for (let i = 1; i <= nPages; i++) {
//         pageNumbers.push(i);
//     }

//     // Function to generate dynamic page numbers based on current page
//     const getDisplayPageNumbers = () => {
//         const maxDisplayedPages = window.innerWidth < 768 ? 5 : 10; // Show 5 pages on mobile, 10 on desktop
//         const startIndex = currentPage <= maxDisplayedPages / 2 ? 0 : currentPage - Math.floor(maxDisplayedPages / 2);
//         // console.log(pageNumbers.slice(startIndex, startIndex + maxDisplayedPages))
//         return pageNumbers.slice(startIndex, startIndex + maxDisplayedPages);
//     };

//     return (
//         <nav>
//             <ul className='pagination pagination-gap'>
//                 {currentPage > 1 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(1 < currentPage)}>
//                             Pre
//                         </a>
//                     </li>
//                 )}

//                 {getDisplayPageNumbers().map((number, index) => (
//                     <li key={index} className={`page-item ${currentPage == number ? 'active' : ''}`}>
//                         <a onClick={() => paginate(number)} className='page-link'>
//                             {number}
//                         </a>
//                     </li>
//                 ))}

//                 {currentPage + 10 <= nPages && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(currentPage + 10)}>
//                             Next
//                         </a>
//                     </li>
//                 )}
//             </ul>
//         </nav>
//     );
// };

// export default Pagination;








// import React from 'react';

// const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage }) => {
//     const pageNumbers = [];
//     const nPages = Math.ceil(totalPosts / postsPerpage);


//     for (let i = 1; i <= nPages; i++) {
//         pageNumbers.push(i);
//     }

//     const getDisplayPageNumbers = () => {
//         const startIndex = (Math.ceil(currentPage / 10) - 1) * 10;
//         return pageNumbers.slice(startIndex, startIndex + 10);
//     };

//     const nextPage = () => {
//                 const nextPage = currentPage + 1;
//                 const nextTenPages = Math.ceil(nextPage / 10) * 10 + 1;
//                 setCurrentPage(nextTenPages <= totalPages ? nextTenPages : totalPages);
//             };

//     return (
//         <nav>
//             <ul className='pagination pagination-gap'>
//                 {currentPage > 10 && (
//                     <li className='page-item'>
//                         <a className='page-link' onClick={() => paginate(currentPage - 10)}>
//                             Previous
//                         </a>
//                     </li>
//                 )}

//                 {getDisplayPageNumbers().map((number, index) => (
//                     <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                         <a onClick={() => paginate(number)} className='page-link'>
//                             {number}
//                         </a>
//                     </li>
//                 ))}

//                 {currentPage + 10 <= nPages && (
//                     <li className='page-item'>
//                         {/* <a className='page-link' onClick={() => paginate(currentPage + 10)}> */}
//                         <a className='page-link' onClick={nextPage()}>
//                             Next
//                         </a>
//                     </li>
//                 )}
//             </ul>
//         </nav>
//     );
// };

// export default Pagination;



// This is All Requirment fill up

// import React from 'react';

// const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage, setCurrentPage }) => {
//     const totalPages = Math.ceil(totalPosts / postsPerpage);

// const nextPage = () => {
//     const nextPage = currentPage + 1;
//     const nextTenPages = Math.ceil(nextPage / 10) * 10 + 1;
//     setCurrentPage(nextTenPages <= totalPages ? nextTenPages : totalPages);
// };

//     const prevPage = () => {
//         const prevPage = currentPage - 1;
//         const prevTenPages = Math.floor(prevPage / 10) * 10;
//         setCurrentPage(prevTenPages >= 1 ? prevTenPages : 1);
//     };

//     const generatePageNumbers = () => {
//         const pageNumbers = [];
//         const startPage = Math.floor((currentPage - 1) / 10) * 10 + 1;
//         const endPage = Math.min(startPage + 9, totalPages);

//         for (let i = startPage; i <= endPage; i++) {
//             pageNumbers.push(i);
//         }
//         return pageNumbers;
//     };

//     return (
//         <nav>
//             <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                     <a className="page-link" onClick={prevPage}>
//                         Pre
//                     </a>
//                 </li>
//                 {generatePageNumbers().map(number => (
//                     <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
//                         <a className="page-link" onClick={() => paginate(number)}>
//                             {number}
//                         </a>
//                     </li>
//                 ))}

//                 {
//                     !generatePageNumbers().includes(totalPages) && 
//                     <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                         <a className="page-link" onClick={nextPage}>
//                             Next
//                         </a>
//                     </li>
//                 }
//             </ul>
//         </nav>
//     );
// };

// export default Pagination;



import React, { useEffect, useState } from 'react';

const Pagination = ({ postsPerpage, totalPosts, paginate, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(totalPosts / postsPerpage);

    const nextPageWeb = () => {
        const nextPage = currentPage + 1;
        const nextTenPages = Math.ceil(nextPage / 10) * 10 + 1;
        setCurrentPage(nextTenPages <= totalPages ? nextTenPages : totalPages);
    };

    const nextPageMobile = () => {
        const nextPage = currentPage + 1;
        const nextTenPages = Math.ceil(nextPage / 5) * 5 + 1;
        setCurrentPage(nextTenPages <= totalPages ? nextTenPages : totalPages);
    };

    const prevPage = () => {
        const prevPages = currentPage - getPageIncrement();
        setCurrentPage(prevPages >= 1 ? prevPages : currentPage = 1);
    };

    const getPageIncrement = () => {
        return window.innerWidth >= 768 ? 10 : 5; // Change to 5 pages on smaller screens
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.floor((currentPage - 1) / getPageIncrement()) * getPageIncrement() + 1;
        const endPage = Math.min(startPage + (getPageIncrement() - 1), totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        
        return pageNumbers;
    };

    useEffect(() => {
        const handleResize = () => {
            // Force re-render when window size changes to adjust pagination
            setCurrentPage(currentPage);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [currentPage, setCurrentPage]);

    return (
        <nav>
            <ul className="pagination">
                {/* <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={prevPage}>
                        Pre
                    </a>
                </li> */}
                <div>
                    <div className='d-none d-md-block d-lg-block'>
                        {currentPage > 10 && (
                            <li className={`page-item`}>
                                <a className="page-link" onClick={prevPage}>
                                    Pre
                                </a>
                            </li>
                        )}
                    </div>
                    <div className='d-block d-md-none d-lg-none'>
                        {currentPage > 5 && (
                            <li className={`page-item`}>
                                <a className="page-link" onClick={prevPage}>
                                    Pre
                                </a>
                            </li>
                        )}
                    </div>
                </div>
                {generatePageNumbers().map(number => (
                    <li key={number} className={`page-item ${currentPage == number ? 'active' : ''}`}>
                        <a className="page-link" onClick={() => paginate(number)}>
                            {number}
                        </a>
                    </li>
                ))}

                <div>
                    <div className='d-none d-md-block d-lg-block'>
                        {
                            !generatePageNumbers().includes(totalPages) &&
                            <li className={`page-item ${currentPage == totalPages ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={nextPageWeb}>
                                    Next
                                </a>
                            </li>
                        }
                    </div>
                    <div className='d-block d-md-none d-lg-none'>
                        {
                            !generatePageNumbers().includes(totalPages) &&
                            <li className={`page-item ${currentPage == totalPages ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={nextPageMobile}>
                                    Next
                                </a>
                            </li>
                        }
                    </div>
                </div>

            </ul>
        </nav>
    );
};

export default Pagination;





















