import React, { useContext } from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import { Link } from "react-router-dom";
import { ThemeContext } from "../ThemeContaxt/ThemeContaxt";
import { GlobalStyles } from "../GlobalStyles/GlobalStyles";
import iosApp from '../images/ios2.jpeg'
import iosAppWeb from '../images/ios .png'
import apkApp from '../images/play-store.png'
import mobileViewAPK from '../images/androaid2.jpeg'
import webApp from '../images/micosoft-store.png'

const Footer = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const theme = {
        background: isDarkMode ? '#222224' : '#fff',
        text: isDarkMode ? '#fff' : '#222224',
    };

    const logoSRC = isDarkMode ? 'assets/images/logo-2.png' : 'assets/images/logo-2.png'

    return (
        <>
            <GlobalStyles theme={theme} />
            <WhatsAppWidget
                phoneNo="+2348155557307"
                position="right"
                widgetWidth="300px"
                widgetWidthMobile="60px"
                autoOpen={false}
                autoOpenTimer={5000}
                messageBox={true}
                messageBoxTxt=""
                iconSize="60"
                iconColor="white"
                iconBgColor="green"
                headerIcon="https://examtice.com/assets/images/white_logo.png"
                headerIconColor="pink"
                headerIconBgColor="black"
                headerTxtColor="black"
                headerBgColor="#ff1d6a"
                headerTitle="Examtice"
                headerCaption="Online"
                bodyBgColor="#bbb"
                chatPersonName="Support"
                chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
                footerBgColor="#999"
                btnBgColor="#ff1d6a"
                btnTxtColor="black"
                btnTxt="Start Chat"
            />

            <footer id="footer" className={`${isDarkMode ? "footer_light" : "footer_dark"} page-footer`}>
                <div className="container">
                    <div className="inner-footer">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footer-nav">
                                    <img src={logoSRC} alt="" />
                                    {/* <p>50 Raritan Center Pkwy, <br />Edison, NJ 08837, USA</p> */}
                                    <div className="d-block d-lg-none d-md-none">
                                        <div className="footer-social middle">
                                            <a href="https://www.facebook.com/examtice" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="https://twitter.com/examtice" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 20 }}><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" fill="#fff" /></svg>
                                            </a>
                                            <a href="https://www.instagram.com/examtice.official/" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </div>
                                    </div>
                                    <span>
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location = "mailto:support@examtice.com";
                                            }}

                                        >
                                            <i className="fas fa-envelope" />
                                            <span className={isDarkMode ? '' : ''}>support@examtice.com</span>
                                        </Link>
                                    </span>
                                    <div className="d-none d-lg-block d-md-block">
                                        <div className="footer-social middle">
                                            <a href="https://www.facebook.com/examtice" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="https://twitter.com/examtice" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 20 }}><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" fill="#fff" /></svg>
                                            </a>
                                            <a href="https://www.instagram.com/examtice.official/" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="d-none d-md-block d-lg-block">
                                        <div>
                                            <a href="assets/apps/examtice.ipa" target='_blank' download="Examtice.ipa" className="footer-download-ios">
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" height="40" width="50" viewBox="0 0 384 512"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" fill="#000" /></svg> */}
                                                <img src={iosAppWeb} alt="" style={{width: '50px', marginRight: '10px'}}/>
                                                <div>
                                                    <small>Download on the</small>
                                                    <h6>APP Store</h6>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="d-block d-md-block d-lg-none">
                                            <a href="assets/apps/examtice.apk" target='_blank' download="Examtice.apk" className="footer-download-apk">
                                                <img src={apkApp} alt="" />
                                                <div>
                                                    <small>GET IT ON</small>
                                                    <h6>Google Play</h6>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="d-none d-md-none d-lg-block">
                                            <a href="assets/apps/examtice-web.exe" target='_blank' download="Examtice.exe" className="footer-download-apk">
                                                <img src={webApp} alt="" />
                                                <div>
                                                    <small>GET IT ON</small>
                                                    <h6 style={{ letterSpacing: 1 }}>Desktop App</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-none d-lg-none d-flex">
                                        <a href="assets/apps/examtice.ipa" target='_blank' download="Examtice.ipa" className="footer-download-ios">
                                            <img src={iosApp} alt="" />
                                        </a>
                                        <a href="assets/apps/examtice.apk" target='_blank' download="Examtice.apk" className="footer-download-apk">
                                            <img className="apk-mobile" src={mobileViewAPK} alt="" />
                                        </a>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-nav-page">
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/free-test">Free test</Link>
                        </li>
                        <li>
                            <Link to="/blog">News</Link>
                        </li>
                        <li>
                            <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact</Link>
                        </li>
                        <li>
                            <Link to="/terms-condition">Terms & Condition</Link>
                        </li>
                        <li>
                            <Link to="/affiliate-terms-condition">Affiliate Terms & Condition</Link>
                        </li>
                    </ul>
                </div>
                <div className="copy-right">
                    <p>©2021 Examtice. All Rights Reserved</p>
                </div>
            </footer>
        </>
    );
};
export default Footer;
