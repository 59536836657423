import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header';
import { ThemeContext } from '../../ThemeContaxt/ThemeContaxt';
import { Link } from 'react-router-dom';
import { GET_RESOURCES } from '../../components/Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import Pagination from '../../Pagination/Pagination';
import Footer from '../../components/Footer';

const Resources = () => {
    const [resourcesData, setResourcesData] = useState([]); // Initialize as an array
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10); // Set the number of resources per page
    const { isDarkMode } = useContext(ThemeContext);

    useEffect(() => {
        getResourcesData();
    }, [currentPage]); // Add currentPage as a dependency to trigger the effect when the page changes

    const getResourcesData = async () => {
        try {
            const { data } = await axios.get(GET_RESOURCES);
            setResourcesData(data.data); // Assuming the array is nested inside a 'data' property
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong, please try again!');
        }
    };

    // console.log(resourcesData)
    const handleDownload = (link) => {
        console.log(link);
    };

    const indexOfLastResource = currentPage * postsPerPage;
    const indexOfFirstResource = indexOfLastResource - postsPerPage;
    const currentResources = resourcesData.slice(indexOfFirstResource, indexOfLastResource);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Header />
            <section className="free-testing-sec">
                <div className="container">
                    <div className={`pagination ${isDarkMode ? 'text_color_light' : 'text_color_dark'}`}>
                        <span>
                            <Link to="/user/dashboard">
                                <i className="fas fa-chevron-left" />
                                Back
                            </Link>
                        </span>
                    </div>

                    <div className="table-responsive">
                        <table className={`${isDarkMode ? 'table table-hover table-dark' : 'table table-white'}`}>
                            <thead>
                                <tr>
                                    <th scope="col">S.no</th>
                                    <th scope="col">Recources Date</th>
                                    <th scope="col">Link</th>
                                    <th scope="col">Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentResources.length &&
                                    currentResources.map((data, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                                            <td>{data.name}</td>
                                            <td vclassName={`${isDarkMode ? 'color_light' : 'color_dark'}`}>{data.link}</td>
                                            <td className="text-center">
                                                {/* <a
                                                    href={data.link}
                                                    className="text-white"
                                                    // onClick={() => handleDownload(data.link)}
                                                    // download={`resource-data-${index}.txt`}
                                                    download="Document.docx"
                                                >
                                                    <i className="fas fa-solid fa-download pointer" />
                                                </a> */}
                                                <a
                                                    href={data.link}
                                                    className={`${isDarkMode ? 'color_light' : 'color_dark'}`}
                                                    download="Document.docx"
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-solid fa-download pointer" />
                                                </a>

                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination postsPerpage={postsPerPage} totalPosts={resourcesData.length} paginate={paginate} setCurrentPage={setCurrentPage} currentPage={currentPage}></Pagination>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Resources;
