import React, { useEffect, useState } from "react";
//import { Link, useHistory } from "react-router-dom";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import axios from "axios";
import { GET_LATEST_BLOGS, GET_SINGLE_BLOG, BLOG_COMMENT, GET_BLOG_COMMENT } from "../components/Api";
import { global } from "../components/Config";
import Spinner from "../components/spinner/Spinner";
import { useContext } from "react";
import { ThemeContext } from "../ThemeContaxt/ThemeContaxt";
import { useSelector } from "react-redux";
import { userAuth } from "../features/userSlice";
import ReCAPTCHA from "react-google-recaptcha";
import Comment from "../components/exam/Comment";

const BlogSingle = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [singleBlog, setBlogPost] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const { slug } = useParams();
    const [loader, setLoader] = useState(false);
    const [verfied, setVerfied] = useState(false)
    const [isRecaptchaSelected, setIsRecaptchaSelected] = useState(false);
    const [comments, setComments] = useState([])
    const [singleBlogId, setSingleBlogId] = useState(null)
    const isAuth = useSelector(userAuth); //using redux useSelector here
    const history = useHistory();

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
    };

    const body = {
        slug: slug,
    };

    //Get courses on page load
    const getSingleBlog = async () => {
        try {
            setLoader(true);

            const {
                data: { data, status, message },
            } = await axios.post(GET_SINGLE_BLOG, body);
            if (status == 200) {
                setLoader(false);
                setBlogPost(data);
                setSingleBlogId(data.id);
                getBlogsComment(data.id);
            } else {
                toast.error(message);
                history.push("/blog");
            }
        } catch (err) {
            setLoader(false);

            toast.error("Something went wrong please try again..!");
        }
    };

    //Get courses on page load
    const getBlogs = async () => {
        try {
            const {
                data: { data, status },
            } = await axios.get(GET_LATEST_BLOGS);
            if (status === 200) {
                data && setBlogs(data);
            }
        } catch (err) {
            toast.error("Something went wrong please try again..!");
        }
    };

    function blogDescription(text) {
        return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    const formatUpdateDate = (date) => {
        const formattedDate = new Date(date);
        return `${formattedDate.getFullYear()}-${(formattedDate.getMonth() + 1).toString().padStart(2, '0')}-${formattedDate.getDate().toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        getSingleBlog();
        getBlogs();
        //getBlogsComment()
    }, [slug]);


    const autoReload = () => {
        // Reload the current page
        window.location.reload();
    };

    function onChange(value) {
        // console.log("Captcha value:", value);
        setVerfied(true)
        setIsRecaptchaSelected(!!value);
    }
    console.log(singleBlogId)
    // get comment 
    const getBlogsComment = async (id) => {

        const body = {
            blog_id: id
        }
        setTimeout(async () => {
            try {
                const {
                    data: { data, status },
                } = await axios.post(GET_BLOG_COMMENT, body);
                console.log(data)
                if (status === 200) {
                    setComments(data)
                }
            }
            catch (err) {
                toast.error("Something went wrong please try again..!");
            }
        }, 2000);
    }



    // console.log(comments)

    // Post a comment
    const handleUserCommnet = async (e) => {
        e.preventDefault()

        // console.log("Testing")
        const form = e.target

        const name = form.name.value;
        const email = form.email.value;
        const commnet = form.comment.value;

        if(!isRecaptchaSelected){
            toast.error("Please Selecte reCAPTCHA")
            return;
        }

        // console.log(name, email, commnet)

        const body = {
            user_name: name,
            user_email: email,
            comment_text: commnet,
            blog_id: singleBlog.id
        }

        // console.log(body)

        try {
            const {
                data: { status, message },
            } = await axios.post(BLOG_COMMENT, body);
            if (status == 200) {
                toast.success(message);
                form.reset()
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // console.log(comments)

    return (
        <>
            <Header />

            <section className="blog-page-main block-element">
                <div className="container">

                    <div className="pagination">
                        <span onClick={autoReload} className={`${isDarkMode ? 'text_color_light' : 'text_color_dark'}`}>
                            <Link to={isAuth ? "/blog" : "/blog"}>
                                <i className="fas fa-chevron-left" />
                                Back
                            </Link>
                        </span>
                    </div>

                    <div className="row">
                        {loader ? (
                            <div className="spinner_div" style={{ minHeight: "400px" }}>
                                <Spinner />
                            </div>
                        ) : (
                            <>
                                <div className="col-md-9">
                                    <div className="blog-content">
                                        <div className="blog-content-inner">
                                            <img
                                                src={
                                                    `${global.API_HOST}assets/images/blogs/` +
                                                    singleBlog.image
                                                }
                                                alt=""
                                            />
                                            <span className={`${isDarkMode ? "blog-date-dark" : "blog-date-light"}`}>Last Update on {formatUpdateDate(singleBlog.updated_at)}</span>
                                            <span>{singleBlog.title}</span>
                                            <div className="content">
                                                {blogDescription(singleBlog.description)}
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                className=""
                                            >

                                                <div>
                                                    {comments?.length > 0 &&
                                                        <Comment
                                                            comments={comments}
                                                        />
                                                    }
                                                </div>
                                                <h3>Comments</h3>
                                                <form onSubmit={handleUserCommnet}>
                                                    <div className="form-group">
                                                        <div className="mb-3 d-flex justify-content-between gx-5">
                                                            <input type="text" placeholder="Name" name="name" style={{ borderRadius: "5px", padding: "5px", width: "100%", marginRight: "20px" }} required />

                                                            <input type="email" placeholder="Email" name="email" style={{ borderRadius: "5px", padding: "5px", width: "100%" }} required />


                                                        </div>

                                                        <div>
                                                            <textarea
                                                                placeholder="Comment"
                                                                // readOnly={!isAuth}
                                                                // value={comment}
                                                                // onChange={(e) => setComment(e.target.value)}
                                                                name="comment"
                                                                style={{ width: "100%", height: "120px", borderRadius: "5px", padding: "5px" }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <ReCAPTCHA
                                                        sitekey="6LdNSR8pAAAAAJWQT2GrNZJ_KkBvxIO1gm_VBo6Q"
                                                        onChange={onChange}
                                                    />


                                                    <div className="form-group" style={{ textAlign: "center" }}>
                                                        <button
                                                            // onClick={handleUserCommnet}
                                                            // disabled={commentLoader}
                                                            // questionId={item.id}
                                                            style={{ padding: "15px 25px", backgroundColor: "#ff1d6a", borderRadius: "5px", color: "#fff", cursor: "pointer" }}
                                                            
                                                        >
                                                            Post comment
                                                        </button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="blog-content-list">
                                        <div className="recen-post">
                                            {" "}
                                            <span>Recent Posts</span>
                                            <ul>
                                                {blogs.length > 0 ? (
                                                    blogs.slice(0, 6).map((item, i) => (
                                                        <li key={i}>
                                                            {" "}
                                                            <Link
                                                                className={`${singleBlog.slug == item.slug ? "d-none" : ""}`}
                                                                to={`/blog/${item.slug}`}
                                                                id={item.slug}
                                                            >
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <p>No Recent Post found..!</p>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
            {/* < ToastContainer /> */}
        </>
    );
};

export default BlogSingle;
