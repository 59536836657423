import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Signin from "./components/modals/signin";
import { useState } from "react";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SignIn from "./pages/Learningmanagement/SignIn";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    var isRole =
        !!Cookies.get("user_data") && JSON.parse(Cookies.get("user_data"));
    var affiliateRole = !!isRole && isRole.affiliate_role;
    const IsAffiliateRole = [];
    const IsLmsRole = [7, 8];


    const [open, setOpen] = useState(false); // testing



    // handle to close the modal
    const handleCloseModal = () => {
        setOpen(false);
    };
    const location = useLocation()
    const parts = location.pathname.split('/');

    // The first part of the pathname
    const firstPart = parts[1];

    // console.log(firstPart);
    // console.log(location)



    return (
        <Route
            {...rest}
            render={(props) =>
                Cookies.get("token") &&
                    Cookies.get("user_data") &&
                    !IsAffiliateRole.includes(affiliateRole) ? (
                    <Component {...props} />
                ) : (
                    <>
                        {IsAffiliateRole.includes(affiliateRole) ? (
                            <Redirect
                                to={{
                                    pathname: "/affiliate/dashboard",
                                    state: { from: props.location },
                                }}
                            />
                        ) : (
                            <>
                                {IsLmsRole.includes(affiliateRole) ? (
                                    <Redirect

                                        to={{
                                            pathname: "/learningmanagement/dashboard",
                                            state: { from: props.location },
                                        }}
                                    />
                                ) : (

                                    <>
                                        {firstPart == 'lms' ? <Redirect
                                            to={{
                                                pathname: "/lms/signin",
                                                state: { from: props.location },
                                            }}
                                        />
                                            :
                                            setOpen(true)
                                        }

                                    </>

                                )}
                            </>
                        )}
                        <Signin show={open} closePop={handleCloseModal} state={{ from: location }} />
                    </>
                )
            }
        />

    );
};

export default ProtectedRoute;
