import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Signin from "../components/modals/signin";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import DarkAndLightMode from "../DarkAndLightMode/DarkAndLightMode";

const Learningmanagementsystem = (props) => {
    const [open, setOpen] = useState(false);
    const handleCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <Header />
            <section className="become-learningmanagement">
                <div className="container">
                    <h2 className="page-heading">LEARNING MANAGEMENT SYSTEM (LMS)</h2>
                    <div className="row">
                        <div className="col-md-6 col-12 mobile-view">
                            <Link className="common-btn" to={`/lms/signin`}>
                                JOIN NOW / LOGIN <br />
                                <small>(for parents and teachers)</small>
                            </Link>
                        </div>
                        <div className="col-md-6 col-12">
                            <Link
                                className="common-btn"
                                to="#"
                                onClick={() => setOpen(true)}
                            >
                                LOGIN AS STUDENT
                            </Link>
                        </div>

                    </div>
                    <br />
                    <h5>ASSIGN TESTS/EXAMS</h5>
                    <p>
                        LMS allows Parents and Teachers to set up a series of tests and exams(Task) for theirstudents
                        /ward to ensure they practice regularly and deepen their knowledge.
                    </p>
                    <h5>MONITOR YOUR STUDENT’S PROGRESS</h5>
                    <p>Follow your student’s scores and testing history - see how diligently they study and improve.
                        Discovering their weaknesses through statistics and reports will help you focus your teaching.
                    </p>

                </div>
            </section>
            <Footer />
            <Signin show={open} closePop={handleCloseModal} />
        </>
    );
};

export default Learningmanagementsystem;
