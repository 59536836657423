import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { GET_REPORTS } from "../../components/Api";
import { getTestDate } from "../../components/CommonFunction";
import Spinner from "../../components/spinner/Spinner";
import Cookies from "js-cookie";
import { useContext } from "react";
import { ThemeContext } from "../../ThemeContaxt/ThemeContaxt";
import Pagination from "../../Pagination/Pagination";

const MyReports = () => {
    //const userData = JSON.parse(useSelector(userProfile).user.user);
    const [loader, setLoader] = useState(false);
    const [report, setReports] = useState([]);
    const [postsPerPage, setPostsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const config = {
        headers: {
            Authorization: Cookies.get("token"),
        },
    };

    useEffect(() => {
        getReports();
    }, []);

    const getReports = async () => {
        try {
            setLoader(true);
            var user_data = JSON.parse(Cookies.get("user_data"));
            const body = {
                userId: user_data.id,
            };
            const {
                data: { message, status, data },
            } = await axios.post(GET_REPORTS, body, config);
            if (status === 200) {
                setLoader(false);
                setReports(data);
            } else {
                setLoader(false);
                toast.error("Something went wrong, please try again.!");
            }
        } catch (error) {
            setLoader(false);
            toast.error("Something went wrong, please try again.!");
        }
    };

    //get refresh token
    // const getRefreshToken = () => {
    // }

    //get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = report.userReport?.slice(indexOfFirstPost, indexOfLastPost)

    // Calculate the correct serial number for each item
    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * postsPerPage + index + 1;
    };

    //change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    return (
        <>
            <Header />

            <section className="free-testing-sec">
                <div className="container">
                    <div className={`pagination ${isDarkMode ? 'text_color_light' : 'text_color_dark'}`}>
                        <span>
                            <Link to="/user/dashboard" >
                                <i className="fas fa-chevron-left" />
                                Reports
                            </Link>
                        </span>
                    </div>

                    {loader ? (
                        <div className="spinner_div" style={{ minHeight: "400px" }}>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <div className="report-menu">
                                <div className="category free-testing-tabs">
                                    <ul>
                                        <li>
                                            <p>{report.userReport?.length}</p>
                                            Tests taken
                                        </li>
                                        <li>
                                            <p>{report.totalQues}</p>
                                            Questions seen
                                        </li>
                                        <li>
                                            <p>{report.totalTime}</p>
                                            Time spent
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className={`${isDarkMode ? 'table table-hover table-dark' : 'table table-hover table-white'}`}>
                                    <thead>
                                        <tr>
                                            <th scope="col">S.no</th>
                                            <th scope="col">Course Name</th>
                                            <th scope="col">Subject Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Time spent</th>
                                            <th scope="col">Score</th>
                                            <th scope="col" style={{ width: "10%" }}>
                                                View report
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {report.userReport?.length ? (
                                            currentPosts.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{calculateSerialNumber(index)}</th>
                                                    <td>{item.course_name}</td>

                                                    <td className="d-lg-none d-md-none d-block">{item.subject_name.length > 20 ? item.subject_name.slice(0, 20) + "..." : item.subject_name}</td>

                                                    <td className="d-lg-block d-md-block d-none">{item.subject_name.length > 40 ? item.subject_name.slice(0, 40) + "..." : item.subject_name}</td>

                                                    <td>{getTestDate(item.created_at)}</td>
                                                    <td>{item.time_spent}</td>
                                                    <td>
                                                        <div className="report-grade-right">
                                                            <span>
                                                                {Math.floor(
                                                                    (item.totaCorrectOption /
                                                                        item.total_questions) *
                                                                    100
                                                                )}
                                                                %
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={"/user/report/" + item.test_id}>View</Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>No data found..!</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                    <Pagination postsPerpage={postsPerPage} totalPosts={report.userReport?.length} paginate={paginate} setCurrentPage={setCurrentPage} currentPage={currentPage}></Pagination>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default MyReports;
